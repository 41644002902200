exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nmn-research-lab-404-tsx": () => import("./../../../src/pages/nmn-research-lab/404.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-404-tsx" */),
  "component---src-pages-nmn-research-lab-company-tsx": () => import("./../../../src/pages/nmn-research-lab/company.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-company-tsx" */),
  "component---src-pages-nmn-research-lab-index-tsx": () => import("./../../../src/pages/nmn-research-lab/index.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-index-tsx" */),
  "component---src-pages-nmn-research-lab-nmn-supplement-404-tsx": () => import("./../../../src/pages/nmn-research-lab/nmn-supplement/404.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-nmn-supplement-404-tsx" */),
  "component---src-pages-nmn-research-lab-nmn-supplement-company-tsx": () => import("./../../../src/pages/nmn-research-lab/nmn-supplement/company.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-nmn-supplement-company-tsx" */),
  "component---src-pages-nmn-research-lab-nmn-supplement-list-tsx": () => import("./../../../src/pages/nmn-research-lab/nmn-supplement/list.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-nmn-supplement-list-tsx" */),
  "component---src-pages-nmn-research-lab-nmn-supplement-sitemap-tsx": () => import("./../../../src/pages/nmn-research-lab/nmn-supplement/sitemap.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-nmn-supplement-sitemap-tsx" */),
  "component---src-pages-nmn-research-lab-sitemap-tsx": () => import("./../../../src/pages/nmn-research-lab/sitemap.tsx" /* webpackChunkName: "component---src-pages-nmn-research-lab-sitemap-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-sleeptech-lab-404-tsx": () => import("./../../../src/pages/sleeptech-lab/404.tsx" /* webpackChunkName: "component---src-pages-sleeptech-lab-404-tsx" */),
  "component---src-pages-sleeptech-lab-company-tsx": () => import("./../../../src/pages/sleeptech-lab/company.tsx" /* webpackChunkName: "component---src-pages-sleeptech-lab-company-tsx" */),
  "component---src-pages-sleeptech-lab-index-tsx": () => import("./../../../src/pages/sleeptech-lab/index.tsx" /* webpackChunkName: "component---src-pages-sleeptech-lab-index-tsx" */),
  "component---src-pages-sleeptech-lab-sitemap-tsx": () => import("./../../../src/pages/sleeptech-lab/sitemap.tsx" /* webpackChunkName: "component---src-pages-sleeptech-lab-sitemap-tsx" */),
  "component---src-templates-nmn-research-lab-article-list-tsx": () => import("./../../../src/templates/nmn-research-lab/article-list.tsx" /* webpackChunkName: "component---src-templates-nmn-research-lab-article-list-tsx" */),
  "component---src-templates-nmn-research-lab-article-post-tsx": () => import("./../../../src/templates/nmn-research-lab/article-post.tsx" /* webpackChunkName: "component---src-templates-nmn-research-lab-article-post-tsx" */),
  "component---src-templates-sleeptech-lab-article-list-tsx": () => import("./../../../src/templates/sleeptech-lab/article-list.tsx" /* webpackChunkName: "component---src-templates-sleeptech-lab-article-list-tsx" */),
  "component---src-templates-sleeptech-lab-article-post-tsx": () => import("./../../../src/templates/sleeptech-lab/article-post.tsx" /* webpackChunkName: "component---src-templates-sleeptech-lab-article-post-tsx" */)
}

